.top-btn button {
    width: 20%;
    color: #fff;
    border: none;
    // #fc153d
    background-color: transparent; /* Bold background color */
    padding-bottom: 5px;
    position: relative;
    overflow: hidden;
    font-size: 200%;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    box-shadow: 0px 8px 15px rgba(252, 21, 37, 0.4); /* Shadow for depth */
    margin: 20px; /* Added margin for spacing */
    transition: all 0.3s ease; /* Smooth transition for hover effects */
    cursor: pointer; /* Cursor change to indicate clickable */

    @media (max-width: 1024){
        font-size: 125%;
        width: 38%;
    }

    @media (max-width: 768px) {
        font-size: 125%;
        width: 44%;
    }

    @media (max-width: 480px) {
        font-size: 100%;
        width: 38%;
    }

}

.top-btn button:hover {
    // background-color: #f06529; /* Change color on hover */
    box-shadow: 0px 8px 20px rgba(240, 101, 41, 0.6); /* Shadow effect on hover */
    transform: translateY(-3px); /* Slight move up */
}

.top-btn button::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 0;
    height: 3px;
    background-color: rgb(252, 21, 37);
    transition: width 0.5s ease, left 0.5s ease;
}

.top-btn button:hover::after {
    width: 100%;
    left: 0;
}

.top-btn .active-btn::after {
    width: 100%;
    left: 0;
}


.resume {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    // background-color: #f9f9f9; /* Light background */
    padding: 20px;
    border-radius: 10px;

    iframe {
        width: 100%;
        height: 370px;
        border: 3px solid rgb(252, 21, 37);
        /* Red border */
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
        /* Soft shadow */
        border-radius: 10px;
        /* Rounded corners */
        max-width: 600px;
        /* Maximum width */
        width: 100%;
        /* Responsive width */
        height: 370px;
        /* ... other styles ... */
    }

    h3 {
        text-align: center;
        color: rgb(252, 21, 37);
        /* Title color */
        margin-bottom: 10px;
    }

    p {
        text-align: center;
        color: #666;
        /* Caption color */
        margin-top: 10px;
    }

    .download-btn {
        background-color: rgb(252, 21, 37);
        font-size: 120%;
        font-weight: 700;
        color: white;
        padding: 10px 20px;
        border-radius: 5px;
        text-decoration: none;
        margin-top: 10px;
        border: 2px solid transparent;
        transition: background-color 0.3s ease, border-color 0.3s ease;
    }

    .download-btn:hover {
        background-color: black;
        border-color: rgb(252, 21, 37);
    }
}

.skill-container {
    ul {
        list-style: none;
    }

    li {
        padding: 1%;
        font-size: 20px;
        color: #fff;
        @media (max-width: 768px) {
            font-size: 12px;
        }
        @media (max-width: 480px) {
            font-size: 10px;
        }
    }

    span {
        font-size: 25px;
        font-weight: 500;
        @media (max-width: 768px) {
            font-size: 14px;
        }
        @media (max-width: 480px) {
            font-size: 12px;
        }
    }
  
}

.experience-container, .projects-desc, .achievement-container{
    color: whitesmoke;
    font-family: Verdana, Geneva, Tahoma, sans-serif;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @keyframes slideIn {
        from {
            transform: translateX(-50px);
            opacity: 0;
        }

        to {
            transform: translateX(0);
            opacity: 1;
        }
    }

    @media (max-width: 768px) {
        font-size: 80%; // Adjusted for better readability
        padding: 1px; // Adjust padding for smaller screens

        ol li {
            padding-top: 2%; // Adjust top padding
        }
    }

    @media (max-width: 480px) {
        font-size: 90%; // Further adjustment for very small screens
        padding: 1px; // Reduce padding for very small screens

        ul li {
            font-size: 100%;
            padding-bottom: 10px; // Adjust bottom padding between list items
        }
    }


    ol {
        list-style: none;
        animation: fadeIn 1s ease-in-out, slideIn 0.9s ease-in-out;

        li {
            padding-top: 1%;
        }
    }

    span {
        font-size: 250%;
        font-weight: 500;
    }

    .dates-span {
        font-size: 10px;
    }

    .company-span {
        font-size: 15px;
    }

    ul {
        list-style: none; // Remove default list styling
        padding-left: 0; // Remove default padding

        li {
            font-size: 125%;
            font-weight: 200;
            position: relative; // Needed for pseudo-element positioning
            padding-left: 20px; // Make space for custom bullet

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                height: 5px;
                width: 5px;
                background-color: rgb(252, 21, 37); // Red bullet point
                border-radius: 50%; // Make it circular
            }
        }
    }
}


@media (max-width: 768px) {
    .resume iframe {
        max-width: 100%;
        height: 280px;
        /* Adjust for smaller screens */
    }
}