.wrap-container{
    display: flex;
    flex-direction: row;
    width: 85vw;
    justify-content: space-between;
    justify-items: normal;
    gap: 10%;

    @media screen and (max-width: 1300px){
      flex-wrap: wrap;
    }

    @media screen and (max-width: 768px){
      flex-wrap: wrap;
    }
}

.contact-form {
  width: 100%;
  margin-top: 20px;

  ul {
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-bottom: 10px;
      opacity: 0;
      overflow: hidden;
      display: block;
      clear: both;
      position: relative;
      animation: fadeInUp 2s 2s;
      animation-fill-mode: forwards;
    }

    li.half {
      width: 49%;
      margin-left: 2%;
      float: left;
      clear: none;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  input[type='text'],
  input[type='email'] {
    width: 100%;
    border: 0;
    background: #115173;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
  }

  textarea {
    width: 100%;
    border: 0;
    background: #115173;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 20px;
    box-sizing: border-box;
    min-height: 150px;
  }

  .flat-button {
    color:  rgb(252,21,37);
    font-size: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: 1px solid  rgb(252,21,37);
    float: left;
    border-radius: 4px;
    background: 0 0;
    text-transform: uppercase;
    float: right;
    text-align: center;
    margin-right: 10px;
  }
}

.contact-page {
  display: flex;
  justify-content: space-between;
  align-items: start;

  .card-container {
    perspective: 1000px; 
    width: 100%; 
    height: auto;
  }

  .card {
    width: 100%;
    height: 300px; 
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.7s;
    cursor: pointer;

    &:hover {
      transform: rotateY(180deg); // Flips the card on hover
    }

    .card-front, .card-back {
      font-family: Arial, Helvetica, sans-serif;
      width: 100%;
      height: 100%;
      position: absolute;
      backface-visibility: hidden; // Hides the back face when turned away
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      background: rgb(134, 133, 133);
      box-shadow:  20px 20px 15px rgba(252, 21, 37, 0.4);
    }

    .card-front {
      color: #fff;
      font-size: 220%;
    }

    .card-back {
      font-size: 150%;
      display: flex;
      flex-direction: column;
      background: #fff; // or any color you prefer
      transform: rotateY(180deg); // Flips the back card
      color: black;
       // Back face styling
    }
  }
}

// Adjust responsiveness
@media screen and (max-width: 768px) {
  .contact-page {
    flex-direction: column;
    align-items: center;

    .card-container {
      width: 100%;
      margin-top: 20px;
      .card-back{
        font-size: 120%;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .map-wrap {
    float: none;
    margin: 0;
    width: 100%;
    height: 400px;
  }
  
  .info-map {
    display: none;
  }
}