.portfolio-page {
  padding-left: 100px;
  padding-right: 50px;
  width: calc(100% - 150px);
  position: initial;
  height: 100%;
  overflow: auto;

  h1.page-title {
    margin-left: 100px;
    margin-top: 100px;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); // Three columns in a row
    gap: 20px;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr); // Two columns for medium screens
    }

    @media (max-width: 480px) {
      grid-template-columns: 1fr; // One column for small screens
    }
  }

  .grid-item {
    position: relative;
    cursor: pointer;

    img {
        width: 100%;
        height: auto;
        display: block;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(252, 21, 37, 0.7);
        color: white;
        opacity: 0;
        transform: translateY(100%); // Start below the bottom
        visibility: hidden;
        transition: opacity 0.3s, transform 1s; // Animate opacity and transform
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        text-align: center;
    }

    .overlay .project-description {
        margin-bottom: 15px;
        font-family: Arial, Helvetica, sans-serif;
    }

    .overlay .btn {
        background: white;
        color: black;
        border: none;
        padding: 10px 20px;
        text-transform: uppercase;
        text-decoration: none;
        transition: background-color 0.3s;

        &:hover {
            background: black;
            color: white;
        }
    }

    &:hover .overlay {
        opacity: 1;
        visibility: visible;
        transform: translateY(0); // Move overlay up
    }
}

  // .work-btn,
 .back-btn {
    background-color: rgb(252, 21, 37);
    font-size: 120%;
    font-weight: 700;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    margin-top: 10px;
    border: 2px solid transparent;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }

  .work-btn:hover, .back-btn:hover {
    background-color: black;
    border-color: rgb(252, 21, 37)
  }

  .work-btn-component {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
    margin-bottom: 2%;
    margin-top: -50px;
  }
}
